import { createFileRoute } from '@tanstack/react-router'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'
import { z } from 'zod'
import { listSearch } from '~/lib/searchSchemas'
import { demandPromotionSearch } from '~/pages/Planning/DemandAndPromotions/common/helpers'

const demandPromotionDetailsSearch = z
  .object({
    productDetails: z.record(z.string(), z.any()),
    keyColumnNames: z.record(z.string(), z.string()),
  })
  .and(demandPromotionSearch)
  .and(listSearch)

export const Route = createFileRoute('/_layout/planning/demand-and-promotions/details/')({
  validateSearch: zodSearchValidator(demandPromotionDetailsSearch),
})
