import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from '~/components/UI/Toast'
import { api } from '~/lib/api'
import { queryClient } from '~/lib/queryClient'
import { FILTERS_SETS, FILTERS_SETS_CREATE } from '~/lib/queryKeys'
import type { ApiResponse, FiltersSetCreateResponse, NewFilter } from '~/types/apiContracts'

interface FilterSetCreateArgs {
  name: string
  datamart: 'supply' | 'sales'
  source: 'app' | 'user' | 'datamart'
  user_id?: number | null
  app_name: string
  filters: NewFilter[]
}

const createFiltersSet = async (filtersSet: FilterSetCreateArgs) => {
  const res = (await api
    .post('configs/query-builder/filter-sets', {
      json: filtersSet,
    })
    .json()) as ApiResponse<FiltersSetCreateResponse>

  return res.data
}

export const useFiltersSetCreateMutation = (moduleId: string) => {
  const { t } = useTranslation()

  return useMutation({
    mutationKey: [FILTERS_SETS_CREATE],
    mutationFn: (filtersSet: FilterSetCreateArgs) => createFiltersSet(filtersSet),
    onSuccess: () => {
      toast({ status: 'success', content: t('statusMessage.savedChangesSuccess') })
      queryClient.invalidateQueries({ queryKey: [FILTERS_SETS, moduleId] })
    },
  })
}
