/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as IndexImport } from './routes/index'
import { Route as LayoutWorkAreaImport } from './routes/_layout.work-area'
import { Route as LayoutReportsAndAnalyticsIndexImport } from './routes/_layout.reports-and-analytics.index'
import { Route as LayoutPlanningIndexImport } from './routes/_layout.planning.index'
import { Route as LayoutAdministrationIndexImport } from './routes/_layout.administration.index'
import { Route as LayoutWorkAreaReplenishmentIndexImport } from './routes/_layout.work-area.replenishment.index'
import { Route as LayoutWorkAreaProcurementIndexImport } from './routes/_layout.work-area.procurement.index'
import { Route as LayoutWorkAreaInventoryBalancingIndexImport } from './routes/_layout.work-area.inventory-balancing.index'
import { Route as LayoutPlanningScenariosIndexImport } from './routes/_layout.planning.scenarios.index'
import { Route as LayoutPlanningPromotionalCampaignsIndexImport } from './routes/_layout.planning.promotional-campaigns.index'
import { Route as LayoutPlanningDemandAndPromotionsIndexImport } from './routes/_layout.planning.demand-and-promotions.index'
import { Route as LayoutAdministrationConfigurationIndexImport } from './routes/_layout.administration.configuration.index'
import { Route as LayoutAdministrationAccessControlIndexImport } from './routes/_layout.administration.access-control.index'
import { Route as LayoutWorkAreaReplenishmentSuggestionsIndexImport } from './routes/_layout.work-area.replenishment.suggestions.index'
import { Route as LayoutWorkAreaReplenishmentOrderIndexImport } from './routes/_layout.work-area.replenishment.order.index'
import { Route as LayoutWorkAreaProcurementOrderIndexImport } from './routes/_layout.work-area.procurement.order.index'
import { Route as LayoutWorkAreaProcurementDashboardIndexImport } from './routes/_layout.work-area.procurement.dashboard.index'
import { Route as LayoutWorkAreaProcurementCalendarIndexImport } from './routes/_layout.work-area.procurement.calendar.index'
import { Route as LayoutPlanningScenariosCreateIndexImport } from './routes/_layout.planning.scenarios.create.index'
import { Route as LayoutPlanningScenariosScenarioIdIndexImport } from './routes/_layout.planning.scenarios.$scenarioId.index'
import { Route as LayoutPlanningDemandAndPromotionsDetailsIndexImport } from './routes/_layout.planning.demand-and-promotions.details.index'
import { Route as LayoutAdministrationConfigurationGeneralParametersIndexImport } from './routes/_layout.administration.configuration.general-parameters.index'
import { Route as LayoutWorkAreaProcurementCalendarOrderIndexImport } from './routes/_layout.work-area.procurement.calendar.order.index'
import { Route as LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexImport } from './routes/_layout.planning.promotional-campaigns.$campaignId.promotion.$promotionId.index'
import { Route as LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdDetailsIndexImport } from './routes/_layout.planning.promotional-campaigns.$campaignId.promotion.$promotionId.details.index'

// Create Virtual Routes

const LogoutLazyImport = createFileRoute('/logout')()
const LayoutLazyImport = createFileRoute('/_layout')()
const LayoutDashboardIndexLazyImport = createFileRoute('/_layout/dashboard/')()
const LayoutAdministrationConfigurationLazyImport = createFileRoute(
  '/_layout/administration/configuration',
)()
const LayoutAdministrationAccessControlLazyImport = createFileRoute(
  '/_layout/administration/access-control',
)()
const LayoutWorkAreaSurplursManagementIndexLazyImport = createFileRoute(
  '/_layout/work-area/surplurs-management/',
)()
const LayoutWorkAreaPriceDefinitionIndexLazyImport = createFileRoute(
  '/_layout/work-area/price-definition/',
)()
const LayoutReportsAndAnalyticsStockPerformanceIndexLazyImport =
  createFileRoute('/_layout/reports-and-analytics/stock-performance/')()
const LayoutReportsAndAnalyticsOverallPerformanceIndexLazyImport =
  createFileRoute('/_layout/reports-and-analytics/overall-performance/')()
const LayoutReportsAndAnalyticsForecastPerformanceIndexLazyImport =
  createFileRoute('/_layout/reports-and-analytics/forecast-performance/')()
const LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyImport =
  createFileRoute('/_layout/reports-and-analytics/commercial-performance/')()
const LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyImport =
  createFileRoute('/_layout/reports-and-analytics/adherence-performance/')()
const LayoutPlanningPromotionalCalendarIndexLazyImport = createFileRoute(
  '/_layout/planning/promotional-calendar/',
)()
const LayoutPlanningProcurementCalendarIndexLazyImport = createFileRoute(
  '/_layout/planning/procurement-calendar/',
)()
const LayoutPlanningInvitedSuppliersIndexLazyImport = createFileRoute(
  '/_layout/planning/invited-suppliers/',
)()
const LayoutPlanningEventsCalendarIndexLazyImport = createFileRoute(
  '/_layout/planning/events-calendar/',
)()
const LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyImport =
  createFileRoute(
    '/_layout/reports-and-analytics/stock-performance/procurement/',
  )()
const LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyImport =
  createFileRoute(
    '/_layout/reports-and-analytics/stock-performance/overstock/',
  )()
const LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyImport =
  createFileRoute('/_layout/reports-and-analytics/stock-performance/metrics/')()
const LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyImport =
  createFileRoute(
    '/_layout/reports-and-analytics/stock-performance/lost-sale/',
  )()
const LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyImport =
  createFileRoute('/_layout/planning/promotional-campaigns/$campaignId/')()
const LayoutAdministrationConfigurationSyntheticProductsIndexLazyImport =
  createFileRoute('/_layout/administration/configuration/synthetic-products/')()
const LayoutAdministrationConfigurationQueryBuilderIndexLazyImport =
  createFileRoute('/_layout/administration/configuration/query-builder/')()
const LayoutAdministrationConfigurationOtherIndexLazyImport = createFileRoute(
  '/_layout/administration/configuration/other/',
)()
const LayoutAdministrationConfigurationEnvironmentVariablesIndexLazyImport =
  createFileRoute(
    '/_layout/administration/configuration/environment-variables/',
  )()
const LayoutAdministrationConfigurationDataAllocationIndexLazyImport =
  createFileRoute('/_layout/administration/configuration/data-allocation/')()
const LayoutAdministrationConfigurationConfigColumnsIndexLazyImport =
  createFileRoute('/_layout/administration/configuration/config-columns/')()
const LayoutAdministrationConfigurationColumnGroupsIndexLazyImport =
  createFileRoute('/_layout/administration/configuration/column-groups/')()
const LayoutAdministrationConfigurationAppFiltersIndexLazyImport =
  createFileRoute('/_layout/administration/configuration/app-filters/')()
const LayoutAdministrationAccessControlUsersIndexLazyImport = createFileRoute(
  '/_layout/administration/access-control/users/',
)()
const LayoutAdministrationAccessControlRolesAndPermissionsIndexLazyImport =
  createFileRoute(
    '/_layout/administration/access-control/roles-and-permissions/',
  )()
const LayoutAdministrationAccessControlDataPermissionIndexLazyImport =
  createFileRoute('/_layout/administration/access-control/data-permission/')()
const LayoutAdministrationConfigurationSyntheticProductsIdIndexLazyImport =
  createFileRoute(
    '/_layout/administration/configuration/synthetic-products/$id/',
  )()
const LayoutAdministrationConfigurationQueryBuilderIdIndexLazyImport =
  createFileRoute('/_layout/administration/configuration/query-builder/$id/')()
const LayoutAdministrationConfigurationColumnGroupsColumnGroupIdIndexLazyImport =
  createFileRoute(
    '/_layout/administration/configuration/column-groups/$columnGroupId/',
  )()
const LayoutAdministrationAccessControlUsersUserIdIndexLazyImport =
  createFileRoute('/_layout/administration/access-control/users/$userId/')()

// Create/Update Routes

const LogoutLazyRoute = LogoutLazyImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/logout.lazy').then((d) => d.Route))

const LayoutLazyRoute = LayoutLazyImport.update({
  id: '/_layout',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/_layout.lazy').then((d) => d.Route))

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const LayoutWorkAreaRoute = LayoutWorkAreaImport.update({
  id: '/work-area',
  path: '/work-area',
  getParentRoute: () => LayoutLazyRoute,
} as any).lazy(() =>
  import('./routes/_layout.work-area.lazy').then((d) => d.Route),
)

const LayoutDashboardIndexLazyRoute = LayoutDashboardIndexLazyImport.update({
  id: '/dashboard/',
  path: '/dashboard/',
  getParentRoute: () => LayoutLazyRoute,
} as any).lazy(() =>
  import('./routes/_layout.dashboard.index.lazy').then((d) => d.Route),
)

const LayoutReportsAndAnalyticsIndexRoute =
  LayoutReportsAndAnalyticsIndexImport.update({
    id: '/reports-and-analytics/',
    path: '/reports-and-analytics/',
    getParentRoute: () => LayoutLazyRoute,
  } as any)

const LayoutPlanningIndexRoute = LayoutPlanningIndexImport.update({
  id: '/planning/',
  path: '/planning/',
  getParentRoute: () => LayoutLazyRoute,
} as any).lazy(() =>
  import('./routes/_layout.planning.index.lazy').then((d) => d.Route),
)

const LayoutAdministrationIndexRoute = LayoutAdministrationIndexImport.update({
  id: '/administration/',
  path: '/administration/',
  getParentRoute: () => LayoutLazyRoute,
} as any)

const LayoutAdministrationConfigurationLazyRoute =
  LayoutAdministrationConfigurationLazyImport.update({
    id: '/administration/configuration',
    path: '/administration/configuration',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.administration.configuration.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdministrationAccessControlLazyRoute =
  LayoutAdministrationAccessControlLazyImport.update({
    id: '/administration/access-control',
    path: '/administration/access-control',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.administration.access-control.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutWorkAreaSurplursManagementIndexLazyRoute =
  LayoutWorkAreaSurplursManagementIndexLazyImport.update({
    id: '/surplurs-management/',
    path: '/surplurs-management/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import('./routes/_layout.work-area.surplurs-management.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutWorkAreaPriceDefinitionIndexLazyRoute =
  LayoutWorkAreaPriceDefinitionIndexLazyImport.update({
    id: '/price-definition/',
    path: '/price-definition/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import('./routes/_layout.work-area.price-definition.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutReportsAndAnalyticsStockPerformanceIndexLazyRoute =
  LayoutReportsAndAnalyticsStockPerformanceIndexLazyImport.update({
    id: '/reports-and-analytics/stock-performance/',
    path: '/reports-and-analytics/stock-performance/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.stock-performance.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutReportsAndAnalyticsOverallPerformanceIndexLazyRoute =
  LayoutReportsAndAnalyticsOverallPerformanceIndexLazyImport.update({
    id: '/reports-and-analytics/overall-performance/',
    path: '/reports-and-analytics/overall-performance/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.overall-performance.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutReportsAndAnalyticsForecastPerformanceIndexLazyRoute =
  LayoutReportsAndAnalyticsForecastPerformanceIndexLazyImport.update({
    id: '/reports-and-analytics/forecast-performance/',
    path: '/reports-and-analytics/forecast-performance/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.forecast-performance.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyRoute =
  LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyImport.update({
    id: '/reports-and-analytics/commercial-performance/',
    path: '/reports-and-analytics/commercial-performance/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.commercial-performance.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyRoute =
  LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyImport.update({
    id: '/reports-and-analytics/adherence-performance/',
    path: '/reports-and-analytics/adherence-performance/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.adherence-performance.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutPlanningPromotionalCalendarIndexLazyRoute =
  LayoutPlanningPromotionalCalendarIndexLazyImport.update({
    id: '/planning/promotional-calendar/',
    path: '/planning/promotional-calendar/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.promotional-calendar.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPlanningProcurementCalendarIndexLazyRoute =
  LayoutPlanningProcurementCalendarIndexLazyImport.update({
    id: '/planning/procurement-calendar/',
    path: '/planning/procurement-calendar/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.procurement-calendar.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPlanningInvitedSuppliersIndexLazyRoute =
  LayoutPlanningInvitedSuppliersIndexLazyImport.update({
    id: '/planning/invited-suppliers/',
    path: '/planning/invited-suppliers/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.invited-suppliers.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPlanningEventsCalendarIndexLazyRoute =
  LayoutPlanningEventsCalendarIndexLazyImport.update({
    id: '/planning/events-calendar/',
    path: '/planning/events-calendar/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.events-calendar.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutWorkAreaReplenishmentIndexRoute =
  LayoutWorkAreaReplenishmentIndexImport.update({
    id: '/replenishment/',
    path: '/replenishment/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import('./routes/_layout.work-area.replenishment.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutWorkAreaProcurementIndexRoute =
  LayoutWorkAreaProcurementIndexImport.update({
    id: '/procurement/',
    path: '/procurement/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import('./routes/_layout.work-area.procurement.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutWorkAreaInventoryBalancingIndexRoute =
  LayoutWorkAreaInventoryBalancingIndexImport.update({
    id: '/inventory-balancing/',
    path: '/inventory-balancing/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any)

const LayoutPlanningScenariosIndexRoute =
  LayoutPlanningScenariosIndexImport.update({
    id: '/planning/scenarios/',
    path: '/planning/scenarios/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.scenarios.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPlanningPromotionalCampaignsIndexRoute =
  LayoutPlanningPromotionalCampaignsIndexImport.update({
    id: '/planning/promotional-campaigns/',
    path: '/planning/promotional-campaigns/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.promotional-campaigns.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPlanningDemandAndPromotionsIndexRoute =
  LayoutPlanningDemandAndPromotionsIndexImport.update({
    id: '/planning/demand-and-promotions/',
    path: '/planning/demand-and-promotions/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.demand-and-promotions.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutAdministrationConfigurationIndexRoute =
  LayoutAdministrationConfigurationIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => LayoutAdministrationConfigurationLazyRoute,
  } as any)

const LayoutAdministrationAccessControlIndexRoute =
  LayoutAdministrationAccessControlIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => LayoutAdministrationAccessControlLazyRoute,
  } as any)

const LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyRoute =
  LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyImport.update({
    id: '/reports-and-analytics/stock-performance/procurement/',
    path: '/reports-and-analytics/stock-performance/procurement/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.stock-performance.procurement.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyRoute =
  LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyImport.update({
    id: '/reports-and-analytics/stock-performance/overstock/',
    path: '/reports-and-analytics/stock-performance/overstock/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.stock-performance.overstock.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyRoute =
  LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyImport.update({
    id: '/reports-and-analytics/stock-performance/metrics/',
    path: '/reports-and-analytics/stock-performance/metrics/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.stock-performance.metrics.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyRoute =
  LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyImport.update({
    id: '/reports-and-analytics/stock-performance/lost-sale/',
    path: '/reports-and-analytics/stock-performance/lost-sale/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.reports-and-analytics.stock-performance.lost-sale.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyRoute =
  LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyImport.update({
    id: '/planning/promotional-campaigns/$campaignId/',
    path: '/planning/promotional-campaigns/$campaignId/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.planning.promotional-campaigns.$campaignId.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationConfigurationSyntheticProductsIndexLazyRoute =
  LayoutAdministrationConfigurationSyntheticProductsIndexLazyImport.update({
    id: '/synthetic-products/',
    path: '/synthetic-products/',
    getParentRoute: () => LayoutAdministrationConfigurationLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.configuration.synthetic-products.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationConfigurationQueryBuilderIndexLazyRoute =
  LayoutAdministrationConfigurationQueryBuilderIndexLazyImport.update({
    id: '/query-builder/',
    path: '/query-builder/',
    getParentRoute: () => LayoutAdministrationConfigurationLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.configuration.query-builder.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationConfigurationOtherIndexLazyRoute =
  LayoutAdministrationConfigurationOtherIndexLazyImport.update({
    id: '/other/',
    path: '/other/',
    getParentRoute: () => LayoutAdministrationConfigurationLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.configuration.other.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationConfigurationEnvironmentVariablesIndexLazyRoute =
  LayoutAdministrationConfigurationEnvironmentVariablesIndexLazyImport.update({
    id: '/environment-variables/',
    path: '/environment-variables/',
    getParentRoute: () => LayoutAdministrationConfigurationLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.configuration.environment-variables.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationConfigurationDataAllocationIndexLazyRoute =
  LayoutAdministrationConfigurationDataAllocationIndexLazyImport.update({
    id: '/data-allocation/',
    path: '/data-allocation/',
    getParentRoute: () => LayoutAdministrationConfigurationLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.configuration.data-allocation.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationConfigurationConfigColumnsIndexLazyRoute =
  LayoutAdministrationConfigurationConfigColumnsIndexLazyImport.update({
    id: '/config-columns/',
    path: '/config-columns/',
    getParentRoute: () => LayoutAdministrationConfigurationLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.configuration.config-columns.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationConfigurationColumnGroupsIndexLazyRoute =
  LayoutAdministrationConfigurationColumnGroupsIndexLazyImport.update({
    id: '/column-groups/',
    path: '/column-groups/',
    getParentRoute: () => LayoutAdministrationConfigurationLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.configuration.column-groups.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationConfigurationAppFiltersIndexLazyRoute =
  LayoutAdministrationConfigurationAppFiltersIndexLazyImport.update({
    id: '/app-filters/',
    path: '/app-filters/',
    getParentRoute: () => LayoutAdministrationConfigurationLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.configuration.app-filters.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationAccessControlUsersIndexLazyRoute =
  LayoutAdministrationAccessControlUsersIndexLazyImport.update({
    id: '/users/',
    path: '/users/',
    getParentRoute: () => LayoutAdministrationAccessControlLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.access-control.users.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationAccessControlRolesAndPermissionsIndexLazyRoute =
  LayoutAdministrationAccessControlRolesAndPermissionsIndexLazyImport.update({
    id: '/roles-and-permissions/',
    path: '/roles-and-permissions/',
    getParentRoute: () => LayoutAdministrationAccessControlLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.access-control.roles-and-permissions.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationAccessControlDataPermissionIndexLazyRoute =
  LayoutAdministrationAccessControlDataPermissionIndexLazyImport.update({
    id: '/data-permission/',
    path: '/data-permission/',
    getParentRoute: () => LayoutAdministrationAccessControlLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.access-control.data-permission.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutWorkAreaReplenishmentSuggestionsIndexRoute =
  LayoutWorkAreaReplenishmentSuggestionsIndexImport.update({
    id: '/replenishment/suggestions/',
    path: '/replenishment/suggestions/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.work-area.replenishment.suggestions.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutWorkAreaReplenishmentOrderIndexRoute =
  LayoutWorkAreaReplenishmentOrderIndexImport.update({
    id: '/replenishment/order/',
    path: '/replenishment/order/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import('./routes/_layout.work-area.replenishment.order.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutWorkAreaProcurementOrderIndexRoute =
  LayoutWorkAreaProcurementOrderIndexImport.update({
    id: '/procurement/order/',
    path: '/procurement/order/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import('./routes/_layout.work-area.procurement.order.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutWorkAreaProcurementDashboardIndexRoute =
  LayoutWorkAreaProcurementDashboardIndexImport.update({
    id: '/procurement/dashboard/',
    path: '/procurement/dashboard/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import('./routes/_layout.work-area.procurement.dashboard.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutWorkAreaProcurementCalendarIndexRoute =
  LayoutWorkAreaProcurementCalendarIndexImport.update({
    id: '/procurement/calendar/',
    path: '/procurement/calendar/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import('./routes/_layout.work-area.procurement.calendar.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPlanningScenariosCreateIndexRoute =
  LayoutPlanningScenariosCreateIndexImport.update({
    id: '/planning/scenarios/create/',
    path: '/planning/scenarios/create/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.scenarios.create.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPlanningScenariosScenarioIdIndexRoute =
  LayoutPlanningScenariosScenarioIdIndexImport.update({
    id: '/planning/scenarios/$scenarioId/',
    path: '/planning/scenarios/$scenarioId/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import('./routes/_layout.planning.scenarios.$scenarioId.index.lazy').then(
      (d) => d.Route,
    ),
  )

const LayoutPlanningDemandAndPromotionsDetailsIndexRoute =
  LayoutPlanningDemandAndPromotionsDetailsIndexImport.update({
    id: '/planning/demand-and-promotions/details/',
    path: '/planning/demand-and-promotions/details/',
    getParentRoute: () => LayoutLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.planning.demand-and-promotions.details.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationConfigurationGeneralParametersIndexRoute =
  LayoutAdministrationConfigurationGeneralParametersIndexImport.update({
    id: '/general-parameters/',
    path: '/general-parameters/',
    getParentRoute: () => LayoutAdministrationConfigurationLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.configuration.general-parameters.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationConfigurationSyntheticProductsIdIndexLazyRoute =
  LayoutAdministrationConfigurationSyntheticProductsIdIndexLazyImport.update({
    id: '/synthetic-products/$id/',
    path: '/synthetic-products/$id/',
    getParentRoute: () => LayoutAdministrationConfigurationLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.configuration.synthetic-products.$id.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationConfigurationQueryBuilderIdIndexLazyRoute =
  LayoutAdministrationConfigurationQueryBuilderIdIndexLazyImport.update({
    id: '/query-builder/$id/',
    path: '/query-builder/$id/',
    getParentRoute: () => LayoutAdministrationConfigurationLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.configuration.query-builder.$id.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationConfigurationColumnGroupsColumnGroupIdIndexLazyRoute =
  LayoutAdministrationConfigurationColumnGroupsColumnGroupIdIndexLazyImport.update(
    {
      id: '/column-groups/$columnGroupId/',
      path: '/column-groups/$columnGroupId/',
      getParentRoute: () => LayoutAdministrationConfigurationLazyRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/_layout.administration.configuration.column-groups.$columnGroupId.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutAdministrationAccessControlUsersUserIdIndexLazyRoute =
  LayoutAdministrationAccessControlUsersUserIdIndexLazyImport.update({
    id: '/users/$userId/',
    path: '/users/$userId/',
    getParentRoute: () => LayoutAdministrationAccessControlLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.administration.access-control.users.$userId.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutWorkAreaProcurementCalendarOrderIndexRoute =
  LayoutWorkAreaProcurementCalendarOrderIndexImport.update({
    id: '/procurement/calendar/order/',
    path: '/procurement/calendar/order/',
    getParentRoute: () => LayoutWorkAreaRoute,
  } as any).lazy(() =>
    import(
      './routes/_layout.work-area.procurement.calendar.order.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexRoute =
  LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexImport.update(
    {
      id: '/planning/promotional-campaigns/$campaignId/promotion/$promotionId/',
      path: '/planning/promotional-campaigns/$campaignId/promotion/$promotionId/',
      getParentRoute: () => LayoutLazyRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/_layout.planning.promotional-campaigns.$campaignId.promotion.$promotionId.index.lazy'
    ).then((d) => d.Route),
  )

const LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdDetailsIndexRoute =
  LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdDetailsIndexImport.update(
    {
      id: '/planning/promotional-campaigns/$campaignId/promotion/$promotionId/details/',
      path: '/planning/promotional-campaigns/$campaignId/promotion/$promotionId/details/',
      getParentRoute: () => LayoutLazyRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/_layout.planning.promotional-campaigns.$campaignId.promotion.$promotionId.details.index.lazy'
    ).then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/_layout': {
      id: '/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LayoutLazyImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutLazyImport
      parentRoute: typeof rootRoute
    }
    '/_layout/work-area': {
      id: '/_layout/work-area'
      path: '/work-area'
      fullPath: '/work-area'
      preLoaderRoute: typeof LayoutWorkAreaImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/administration/access-control': {
      id: '/_layout/administration/access-control'
      path: '/administration/access-control'
      fullPath: '/administration/access-control'
      preLoaderRoute: typeof LayoutAdministrationAccessControlLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/administration/configuration': {
      id: '/_layout/administration/configuration'
      path: '/administration/configuration'
      fullPath: '/administration/configuration'
      preLoaderRoute: typeof LayoutAdministrationConfigurationLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/administration/': {
      id: '/_layout/administration/'
      path: '/administration'
      fullPath: '/administration'
      preLoaderRoute: typeof LayoutAdministrationIndexImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/': {
      id: '/_layout/planning/'
      path: '/planning'
      fullPath: '/planning'
      preLoaderRoute: typeof LayoutPlanningIndexImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/': {
      id: '/_layout/reports-and-analytics/'
      path: '/reports-and-analytics'
      fullPath: '/reports-and-analytics'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsIndexImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/dashboard/': {
      id: '/_layout/dashboard/'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof LayoutDashboardIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/administration/access-control/': {
      id: '/_layout/administration/access-control/'
      path: '/'
      fullPath: '/administration/access-control/'
      preLoaderRoute: typeof LayoutAdministrationAccessControlIndexImport
      parentRoute: typeof LayoutAdministrationAccessControlLazyImport
    }
    '/_layout/administration/configuration/': {
      id: '/_layout/administration/configuration/'
      path: '/'
      fullPath: '/administration/configuration/'
      preLoaderRoute: typeof LayoutAdministrationConfigurationIndexImport
      parentRoute: typeof LayoutAdministrationConfigurationLazyImport
    }
    '/_layout/planning/demand-and-promotions/': {
      id: '/_layout/planning/demand-and-promotions/'
      path: '/planning/demand-and-promotions'
      fullPath: '/planning/demand-and-promotions'
      preLoaderRoute: typeof LayoutPlanningDemandAndPromotionsIndexImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/promotional-campaigns/': {
      id: '/_layout/planning/promotional-campaigns/'
      path: '/planning/promotional-campaigns'
      fullPath: '/planning/promotional-campaigns'
      preLoaderRoute: typeof LayoutPlanningPromotionalCampaignsIndexImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/scenarios/': {
      id: '/_layout/planning/scenarios/'
      path: '/planning/scenarios'
      fullPath: '/planning/scenarios'
      preLoaderRoute: typeof LayoutPlanningScenariosIndexImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/work-area/inventory-balancing/': {
      id: '/_layout/work-area/inventory-balancing/'
      path: '/inventory-balancing'
      fullPath: '/work-area/inventory-balancing'
      preLoaderRoute: typeof LayoutWorkAreaInventoryBalancingIndexImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/work-area/procurement/': {
      id: '/_layout/work-area/procurement/'
      path: '/procurement'
      fullPath: '/work-area/procurement'
      preLoaderRoute: typeof LayoutWorkAreaProcurementIndexImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/work-area/replenishment/': {
      id: '/_layout/work-area/replenishment/'
      path: '/replenishment'
      fullPath: '/work-area/replenishment'
      preLoaderRoute: typeof LayoutWorkAreaReplenishmentIndexImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/planning/events-calendar/': {
      id: '/_layout/planning/events-calendar/'
      path: '/planning/events-calendar'
      fullPath: '/planning/events-calendar'
      preLoaderRoute: typeof LayoutPlanningEventsCalendarIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/invited-suppliers/': {
      id: '/_layout/planning/invited-suppliers/'
      path: '/planning/invited-suppliers'
      fullPath: '/planning/invited-suppliers'
      preLoaderRoute: typeof LayoutPlanningInvitedSuppliersIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/procurement-calendar/': {
      id: '/_layout/planning/procurement-calendar/'
      path: '/planning/procurement-calendar'
      fullPath: '/planning/procurement-calendar'
      preLoaderRoute: typeof LayoutPlanningProcurementCalendarIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/promotional-calendar/': {
      id: '/_layout/planning/promotional-calendar/'
      path: '/planning/promotional-calendar'
      fullPath: '/planning/promotional-calendar'
      preLoaderRoute: typeof LayoutPlanningPromotionalCalendarIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/adherence-performance/': {
      id: '/_layout/reports-and-analytics/adherence-performance/'
      path: '/reports-and-analytics/adherence-performance'
      fullPath: '/reports-and-analytics/adherence-performance'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/commercial-performance/': {
      id: '/_layout/reports-and-analytics/commercial-performance/'
      path: '/reports-and-analytics/commercial-performance'
      fullPath: '/reports-and-analytics/commercial-performance'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/forecast-performance/': {
      id: '/_layout/reports-and-analytics/forecast-performance/'
      path: '/reports-and-analytics/forecast-performance'
      fullPath: '/reports-and-analytics/forecast-performance'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsForecastPerformanceIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/overall-performance/': {
      id: '/_layout/reports-and-analytics/overall-performance/'
      path: '/reports-and-analytics/overall-performance'
      fullPath: '/reports-and-analytics/overall-performance'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsOverallPerformanceIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/stock-performance/': {
      id: '/_layout/reports-and-analytics/stock-performance/'
      path: '/reports-and-analytics/stock-performance'
      fullPath: '/reports-and-analytics/stock-performance'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsStockPerformanceIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/work-area/price-definition/': {
      id: '/_layout/work-area/price-definition/'
      path: '/price-definition'
      fullPath: '/work-area/price-definition'
      preLoaderRoute: typeof LayoutWorkAreaPriceDefinitionIndexLazyImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/work-area/surplurs-management/': {
      id: '/_layout/work-area/surplurs-management/'
      path: '/surplurs-management'
      fullPath: '/work-area/surplurs-management'
      preLoaderRoute: typeof LayoutWorkAreaSurplursManagementIndexLazyImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/administration/configuration/general-parameters/': {
      id: '/_layout/administration/configuration/general-parameters/'
      path: '/general-parameters'
      fullPath: '/administration/configuration/general-parameters'
      preLoaderRoute: typeof LayoutAdministrationConfigurationGeneralParametersIndexImport
      parentRoute: typeof LayoutAdministrationConfigurationLazyImport
    }
    '/_layout/planning/demand-and-promotions/details/': {
      id: '/_layout/planning/demand-and-promotions/details/'
      path: '/planning/demand-and-promotions/details'
      fullPath: '/planning/demand-and-promotions/details'
      preLoaderRoute: typeof LayoutPlanningDemandAndPromotionsDetailsIndexImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/scenarios/$scenarioId/': {
      id: '/_layout/planning/scenarios/$scenarioId/'
      path: '/planning/scenarios/$scenarioId'
      fullPath: '/planning/scenarios/$scenarioId'
      preLoaderRoute: typeof LayoutPlanningScenariosScenarioIdIndexImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/scenarios/create/': {
      id: '/_layout/planning/scenarios/create/'
      path: '/planning/scenarios/create'
      fullPath: '/planning/scenarios/create'
      preLoaderRoute: typeof LayoutPlanningScenariosCreateIndexImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/work-area/procurement/calendar/': {
      id: '/_layout/work-area/procurement/calendar/'
      path: '/procurement/calendar'
      fullPath: '/work-area/procurement/calendar'
      preLoaderRoute: typeof LayoutWorkAreaProcurementCalendarIndexImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/work-area/procurement/dashboard/': {
      id: '/_layout/work-area/procurement/dashboard/'
      path: '/procurement/dashboard'
      fullPath: '/work-area/procurement/dashboard'
      preLoaderRoute: typeof LayoutWorkAreaProcurementDashboardIndexImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/work-area/procurement/order/': {
      id: '/_layout/work-area/procurement/order/'
      path: '/procurement/order'
      fullPath: '/work-area/procurement/order'
      preLoaderRoute: typeof LayoutWorkAreaProcurementOrderIndexImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/work-area/replenishment/order/': {
      id: '/_layout/work-area/replenishment/order/'
      path: '/replenishment/order'
      fullPath: '/work-area/replenishment/order'
      preLoaderRoute: typeof LayoutWorkAreaReplenishmentOrderIndexImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/work-area/replenishment/suggestions/': {
      id: '/_layout/work-area/replenishment/suggestions/'
      path: '/replenishment/suggestions'
      fullPath: '/work-area/replenishment/suggestions'
      preLoaderRoute: typeof LayoutWorkAreaReplenishmentSuggestionsIndexImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/administration/access-control/data-permission/': {
      id: '/_layout/administration/access-control/data-permission/'
      path: '/data-permission'
      fullPath: '/administration/access-control/data-permission'
      preLoaderRoute: typeof LayoutAdministrationAccessControlDataPermissionIndexLazyImport
      parentRoute: typeof LayoutAdministrationAccessControlLazyImport
    }
    '/_layout/administration/access-control/roles-and-permissions/': {
      id: '/_layout/administration/access-control/roles-and-permissions/'
      path: '/roles-and-permissions'
      fullPath: '/administration/access-control/roles-and-permissions'
      preLoaderRoute: typeof LayoutAdministrationAccessControlRolesAndPermissionsIndexLazyImport
      parentRoute: typeof LayoutAdministrationAccessControlLazyImport
    }
    '/_layout/administration/access-control/users/': {
      id: '/_layout/administration/access-control/users/'
      path: '/users'
      fullPath: '/administration/access-control/users'
      preLoaderRoute: typeof LayoutAdministrationAccessControlUsersIndexLazyImport
      parentRoute: typeof LayoutAdministrationAccessControlLazyImport
    }
    '/_layout/administration/configuration/app-filters/': {
      id: '/_layout/administration/configuration/app-filters/'
      path: '/app-filters'
      fullPath: '/administration/configuration/app-filters'
      preLoaderRoute: typeof LayoutAdministrationConfigurationAppFiltersIndexLazyImport
      parentRoute: typeof LayoutAdministrationConfigurationLazyImport
    }
    '/_layout/administration/configuration/column-groups/': {
      id: '/_layout/administration/configuration/column-groups/'
      path: '/column-groups'
      fullPath: '/administration/configuration/column-groups'
      preLoaderRoute: typeof LayoutAdministrationConfigurationColumnGroupsIndexLazyImport
      parentRoute: typeof LayoutAdministrationConfigurationLazyImport
    }
    '/_layout/administration/configuration/config-columns/': {
      id: '/_layout/administration/configuration/config-columns/'
      path: '/config-columns'
      fullPath: '/administration/configuration/config-columns'
      preLoaderRoute: typeof LayoutAdministrationConfigurationConfigColumnsIndexLazyImport
      parentRoute: typeof LayoutAdministrationConfigurationLazyImport
    }
    '/_layout/administration/configuration/data-allocation/': {
      id: '/_layout/administration/configuration/data-allocation/'
      path: '/data-allocation'
      fullPath: '/administration/configuration/data-allocation'
      preLoaderRoute: typeof LayoutAdministrationConfigurationDataAllocationIndexLazyImport
      parentRoute: typeof LayoutAdministrationConfigurationLazyImport
    }
    '/_layout/administration/configuration/environment-variables/': {
      id: '/_layout/administration/configuration/environment-variables/'
      path: '/environment-variables'
      fullPath: '/administration/configuration/environment-variables'
      preLoaderRoute: typeof LayoutAdministrationConfigurationEnvironmentVariablesIndexLazyImport
      parentRoute: typeof LayoutAdministrationConfigurationLazyImport
    }
    '/_layout/administration/configuration/other/': {
      id: '/_layout/administration/configuration/other/'
      path: '/other'
      fullPath: '/administration/configuration/other'
      preLoaderRoute: typeof LayoutAdministrationConfigurationOtherIndexLazyImport
      parentRoute: typeof LayoutAdministrationConfigurationLazyImport
    }
    '/_layout/administration/configuration/query-builder/': {
      id: '/_layout/administration/configuration/query-builder/'
      path: '/query-builder'
      fullPath: '/administration/configuration/query-builder'
      preLoaderRoute: typeof LayoutAdministrationConfigurationQueryBuilderIndexLazyImport
      parentRoute: typeof LayoutAdministrationConfigurationLazyImport
    }
    '/_layout/administration/configuration/synthetic-products/': {
      id: '/_layout/administration/configuration/synthetic-products/'
      path: '/synthetic-products'
      fullPath: '/administration/configuration/synthetic-products'
      preLoaderRoute: typeof LayoutAdministrationConfigurationSyntheticProductsIndexLazyImport
      parentRoute: typeof LayoutAdministrationConfigurationLazyImport
    }
    '/_layout/planning/promotional-campaigns/$campaignId/': {
      id: '/_layout/planning/promotional-campaigns/$campaignId/'
      path: '/planning/promotional-campaigns/$campaignId'
      fullPath: '/planning/promotional-campaigns/$campaignId'
      preLoaderRoute: typeof LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/stock-performance/lost-sale/': {
      id: '/_layout/reports-and-analytics/stock-performance/lost-sale/'
      path: '/reports-and-analytics/stock-performance/lost-sale'
      fullPath: '/reports-and-analytics/stock-performance/lost-sale'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/stock-performance/metrics/': {
      id: '/_layout/reports-and-analytics/stock-performance/metrics/'
      path: '/reports-and-analytics/stock-performance/metrics'
      fullPath: '/reports-and-analytics/stock-performance/metrics'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/stock-performance/overstock/': {
      id: '/_layout/reports-and-analytics/stock-performance/overstock/'
      path: '/reports-and-analytics/stock-performance/overstock'
      fullPath: '/reports-and-analytics/stock-performance/overstock'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/reports-and-analytics/stock-performance/procurement/': {
      id: '/_layout/reports-and-analytics/stock-performance/procurement/'
      path: '/reports-and-analytics/stock-performance/procurement'
      fullPath: '/reports-and-analytics/stock-performance/procurement'
      preLoaderRoute: typeof LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/work-area/procurement/calendar/order/': {
      id: '/_layout/work-area/procurement/calendar/order/'
      path: '/procurement/calendar/order'
      fullPath: '/work-area/procurement/calendar/order'
      preLoaderRoute: typeof LayoutWorkAreaProcurementCalendarOrderIndexImport
      parentRoute: typeof LayoutWorkAreaImport
    }
    '/_layout/administration/access-control/users/$userId/': {
      id: '/_layout/administration/access-control/users/$userId/'
      path: '/users/$userId'
      fullPath: '/administration/access-control/users/$userId'
      preLoaderRoute: typeof LayoutAdministrationAccessControlUsersUserIdIndexLazyImport
      parentRoute: typeof LayoutAdministrationAccessControlLazyImport
    }
    '/_layout/administration/configuration/column-groups/$columnGroupId/': {
      id: '/_layout/administration/configuration/column-groups/$columnGroupId/'
      path: '/column-groups/$columnGroupId'
      fullPath: '/administration/configuration/column-groups/$columnGroupId'
      preLoaderRoute: typeof LayoutAdministrationConfigurationColumnGroupsColumnGroupIdIndexLazyImport
      parentRoute: typeof LayoutAdministrationConfigurationLazyImport
    }
    '/_layout/administration/configuration/query-builder/$id/': {
      id: '/_layout/administration/configuration/query-builder/$id/'
      path: '/query-builder/$id'
      fullPath: '/administration/configuration/query-builder/$id'
      preLoaderRoute: typeof LayoutAdministrationConfigurationQueryBuilderIdIndexLazyImport
      parentRoute: typeof LayoutAdministrationConfigurationLazyImport
    }
    '/_layout/administration/configuration/synthetic-products/$id/': {
      id: '/_layout/administration/configuration/synthetic-products/$id/'
      path: '/synthetic-products/$id'
      fullPath: '/administration/configuration/synthetic-products/$id'
      preLoaderRoute: typeof LayoutAdministrationConfigurationSyntheticProductsIdIndexLazyImport
      parentRoute: typeof LayoutAdministrationConfigurationLazyImport
    }
    '/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/': {
      id: '/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/'
      path: '/planning/promotional-campaigns/$campaignId/promotion/$promotionId'
      fullPath: '/planning/promotional-campaigns/$campaignId/promotion/$promotionId'
      preLoaderRoute: typeof LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexImport
      parentRoute: typeof LayoutLazyImport
    }
    '/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/details/': {
      id: '/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/details/'
      path: '/planning/promotional-campaigns/$campaignId/promotion/$promotionId/details'
      fullPath: '/planning/promotional-campaigns/$campaignId/promotion/$promotionId/details'
      preLoaderRoute: typeof LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdDetailsIndexImport
      parentRoute: typeof LayoutLazyImport
    }
  }
}

// Create and export the route tree

interface LayoutWorkAreaRouteChildren {
  LayoutWorkAreaInventoryBalancingIndexRoute: typeof LayoutWorkAreaInventoryBalancingIndexRoute
  LayoutWorkAreaProcurementIndexRoute: typeof LayoutWorkAreaProcurementIndexRoute
  LayoutWorkAreaReplenishmentIndexRoute: typeof LayoutWorkAreaReplenishmentIndexRoute
  LayoutWorkAreaPriceDefinitionIndexLazyRoute: typeof LayoutWorkAreaPriceDefinitionIndexLazyRoute
  LayoutWorkAreaSurplursManagementIndexLazyRoute: typeof LayoutWorkAreaSurplursManagementIndexLazyRoute
  LayoutWorkAreaProcurementCalendarIndexRoute: typeof LayoutWorkAreaProcurementCalendarIndexRoute
  LayoutWorkAreaProcurementDashboardIndexRoute: typeof LayoutWorkAreaProcurementDashboardIndexRoute
  LayoutWorkAreaProcurementOrderIndexRoute: typeof LayoutWorkAreaProcurementOrderIndexRoute
  LayoutWorkAreaReplenishmentOrderIndexRoute: typeof LayoutWorkAreaReplenishmentOrderIndexRoute
  LayoutWorkAreaReplenishmentSuggestionsIndexRoute: typeof LayoutWorkAreaReplenishmentSuggestionsIndexRoute
  LayoutWorkAreaProcurementCalendarOrderIndexRoute: typeof LayoutWorkAreaProcurementCalendarOrderIndexRoute
}

const LayoutWorkAreaRouteChildren: LayoutWorkAreaRouteChildren = {
  LayoutWorkAreaInventoryBalancingIndexRoute:
    LayoutWorkAreaInventoryBalancingIndexRoute,
  LayoutWorkAreaProcurementIndexRoute: LayoutWorkAreaProcurementIndexRoute,
  LayoutWorkAreaReplenishmentIndexRoute: LayoutWorkAreaReplenishmentIndexRoute,
  LayoutWorkAreaPriceDefinitionIndexLazyRoute:
    LayoutWorkAreaPriceDefinitionIndexLazyRoute,
  LayoutWorkAreaSurplursManagementIndexLazyRoute:
    LayoutWorkAreaSurplursManagementIndexLazyRoute,
  LayoutWorkAreaProcurementCalendarIndexRoute:
    LayoutWorkAreaProcurementCalendarIndexRoute,
  LayoutWorkAreaProcurementDashboardIndexRoute:
    LayoutWorkAreaProcurementDashboardIndexRoute,
  LayoutWorkAreaProcurementOrderIndexRoute:
    LayoutWorkAreaProcurementOrderIndexRoute,
  LayoutWorkAreaReplenishmentOrderIndexRoute:
    LayoutWorkAreaReplenishmentOrderIndexRoute,
  LayoutWorkAreaReplenishmentSuggestionsIndexRoute:
    LayoutWorkAreaReplenishmentSuggestionsIndexRoute,
  LayoutWorkAreaProcurementCalendarOrderIndexRoute:
    LayoutWorkAreaProcurementCalendarOrderIndexRoute,
}

const LayoutWorkAreaRouteWithChildren = LayoutWorkAreaRoute._addFileChildren(
  LayoutWorkAreaRouteChildren,
)

interface LayoutAdministrationAccessControlLazyRouteChildren {
  LayoutAdministrationAccessControlIndexRoute: typeof LayoutAdministrationAccessControlIndexRoute
  LayoutAdministrationAccessControlDataPermissionIndexLazyRoute: typeof LayoutAdministrationAccessControlDataPermissionIndexLazyRoute
  LayoutAdministrationAccessControlRolesAndPermissionsIndexLazyRoute: typeof LayoutAdministrationAccessControlRolesAndPermissionsIndexLazyRoute
  LayoutAdministrationAccessControlUsersIndexLazyRoute: typeof LayoutAdministrationAccessControlUsersIndexLazyRoute
  LayoutAdministrationAccessControlUsersUserIdIndexLazyRoute: typeof LayoutAdministrationAccessControlUsersUserIdIndexLazyRoute
}

const LayoutAdministrationAccessControlLazyRouteChildren: LayoutAdministrationAccessControlLazyRouteChildren =
  {
    LayoutAdministrationAccessControlIndexRoute:
      LayoutAdministrationAccessControlIndexRoute,
    LayoutAdministrationAccessControlDataPermissionIndexLazyRoute:
      LayoutAdministrationAccessControlDataPermissionIndexLazyRoute,
    LayoutAdministrationAccessControlRolesAndPermissionsIndexLazyRoute:
      LayoutAdministrationAccessControlRolesAndPermissionsIndexLazyRoute,
    LayoutAdministrationAccessControlUsersIndexLazyRoute:
      LayoutAdministrationAccessControlUsersIndexLazyRoute,
    LayoutAdministrationAccessControlUsersUserIdIndexLazyRoute:
      LayoutAdministrationAccessControlUsersUserIdIndexLazyRoute,
  }

const LayoutAdministrationAccessControlLazyRouteWithChildren =
  LayoutAdministrationAccessControlLazyRoute._addFileChildren(
    LayoutAdministrationAccessControlLazyRouteChildren,
  )

interface LayoutAdministrationConfigurationLazyRouteChildren {
  LayoutAdministrationConfigurationIndexRoute: typeof LayoutAdministrationConfigurationIndexRoute
  LayoutAdministrationConfigurationGeneralParametersIndexRoute: typeof LayoutAdministrationConfigurationGeneralParametersIndexRoute
  LayoutAdministrationConfigurationAppFiltersIndexLazyRoute: typeof LayoutAdministrationConfigurationAppFiltersIndexLazyRoute
  LayoutAdministrationConfigurationColumnGroupsIndexLazyRoute: typeof LayoutAdministrationConfigurationColumnGroupsIndexLazyRoute
  LayoutAdministrationConfigurationConfigColumnsIndexLazyRoute: typeof LayoutAdministrationConfigurationConfigColumnsIndexLazyRoute
  LayoutAdministrationConfigurationDataAllocationIndexLazyRoute: typeof LayoutAdministrationConfigurationDataAllocationIndexLazyRoute
  LayoutAdministrationConfigurationEnvironmentVariablesIndexLazyRoute: typeof LayoutAdministrationConfigurationEnvironmentVariablesIndexLazyRoute
  LayoutAdministrationConfigurationOtherIndexLazyRoute: typeof LayoutAdministrationConfigurationOtherIndexLazyRoute
  LayoutAdministrationConfigurationQueryBuilderIndexLazyRoute: typeof LayoutAdministrationConfigurationQueryBuilderIndexLazyRoute
  LayoutAdministrationConfigurationSyntheticProductsIndexLazyRoute: typeof LayoutAdministrationConfigurationSyntheticProductsIndexLazyRoute
  LayoutAdministrationConfigurationColumnGroupsColumnGroupIdIndexLazyRoute: typeof LayoutAdministrationConfigurationColumnGroupsColumnGroupIdIndexLazyRoute
  LayoutAdministrationConfigurationQueryBuilderIdIndexLazyRoute: typeof LayoutAdministrationConfigurationQueryBuilderIdIndexLazyRoute
  LayoutAdministrationConfigurationSyntheticProductsIdIndexLazyRoute: typeof LayoutAdministrationConfigurationSyntheticProductsIdIndexLazyRoute
}

const LayoutAdministrationConfigurationLazyRouteChildren: LayoutAdministrationConfigurationLazyRouteChildren =
  {
    LayoutAdministrationConfigurationIndexRoute:
      LayoutAdministrationConfigurationIndexRoute,
    LayoutAdministrationConfigurationGeneralParametersIndexRoute:
      LayoutAdministrationConfigurationGeneralParametersIndexRoute,
    LayoutAdministrationConfigurationAppFiltersIndexLazyRoute:
      LayoutAdministrationConfigurationAppFiltersIndexLazyRoute,
    LayoutAdministrationConfigurationColumnGroupsIndexLazyRoute:
      LayoutAdministrationConfigurationColumnGroupsIndexLazyRoute,
    LayoutAdministrationConfigurationConfigColumnsIndexLazyRoute:
      LayoutAdministrationConfigurationConfigColumnsIndexLazyRoute,
    LayoutAdministrationConfigurationDataAllocationIndexLazyRoute:
      LayoutAdministrationConfigurationDataAllocationIndexLazyRoute,
    LayoutAdministrationConfigurationEnvironmentVariablesIndexLazyRoute:
      LayoutAdministrationConfigurationEnvironmentVariablesIndexLazyRoute,
    LayoutAdministrationConfigurationOtherIndexLazyRoute:
      LayoutAdministrationConfigurationOtherIndexLazyRoute,
    LayoutAdministrationConfigurationQueryBuilderIndexLazyRoute:
      LayoutAdministrationConfigurationQueryBuilderIndexLazyRoute,
    LayoutAdministrationConfigurationSyntheticProductsIndexLazyRoute:
      LayoutAdministrationConfigurationSyntheticProductsIndexLazyRoute,
    LayoutAdministrationConfigurationColumnGroupsColumnGroupIdIndexLazyRoute:
      LayoutAdministrationConfigurationColumnGroupsColumnGroupIdIndexLazyRoute,
    LayoutAdministrationConfigurationQueryBuilderIdIndexLazyRoute:
      LayoutAdministrationConfigurationQueryBuilderIdIndexLazyRoute,
    LayoutAdministrationConfigurationSyntheticProductsIdIndexLazyRoute:
      LayoutAdministrationConfigurationSyntheticProductsIdIndexLazyRoute,
  }

const LayoutAdministrationConfigurationLazyRouteWithChildren =
  LayoutAdministrationConfigurationLazyRoute._addFileChildren(
    LayoutAdministrationConfigurationLazyRouteChildren,
  )

interface LayoutLazyRouteChildren {
  LayoutWorkAreaRoute: typeof LayoutWorkAreaRouteWithChildren
  LayoutAdministrationAccessControlLazyRoute: typeof LayoutAdministrationAccessControlLazyRouteWithChildren
  LayoutAdministrationConfigurationLazyRoute: typeof LayoutAdministrationConfigurationLazyRouteWithChildren
  LayoutAdministrationIndexRoute: typeof LayoutAdministrationIndexRoute
  LayoutPlanningIndexRoute: typeof LayoutPlanningIndexRoute
  LayoutReportsAndAnalyticsIndexRoute: typeof LayoutReportsAndAnalyticsIndexRoute
  LayoutDashboardIndexLazyRoute: typeof LayoutDashboardIndexLazyRoute
  LayoutPlanningDemandAndPromotionsIndexRoute: typeof LayoutPlanningDemandAndPromotionsIndexRoute
  LayoutPlanningPromotionalCampaignsIndexRoute: typeof LayoutPlanningPromotionalCampaignsIndexRoute
  LayoutPlanningScenariosIndexRoute: typeof LayoutPlanningScenariosIndexRoute
  LayoutPlanningEventsCalendarIndexLazyRoute: typeof LayoutPlanningEventsCalendarIndexLazyRoute
  LayoutPlanningInvitedSuppliersIndexLazyRoute: typeof LayoutPlanningInvitedSuppliersIndexLazyRoute
  LayoutPlanningProcurementCalendarIndexLazyRoute: typeof LayoutPlanningProcurementCalendarIndexLazyRoute
  LayoutPlanningPromotionalCalendarIndexLazyRoute: typeof LayoutPlanningPromotionalCalendarIndexLazyRoute
  LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyRoute: typeof LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyRoute
  LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyRoute: typeof LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyRoute
  LayoutReportsAndAnalyticsForecastPerformanceIndexLazyRoute: typeof LayoutReportsAndAnalyticsForecastPerformanceIndexLazyRoute
  LayoutReportsAndAnalyticsOverallPerformanceIndexLazyRoute: typeof LayoutReportsAndAnalyticsOverallPerformanceIndexLazyRoute
  LayoutReportsAndAnalyticsStockPerformanceIndexLazyRoute: typeof LayoutReportsAndAnalyticsStockPerformanceIndexLazyRoute
  LayoutPlanningDemandAndPromotionsDetailsIndexRoute: typeof LayoutPlanningDemandAndPromotionsDetailsIndexRoute
  LayoutPlanningScenariosScenarioIdIndexRoute: typeof LayoutPlanningScenariosScenarioIdIndexRoute
  LayoutPlanningScenariosCreateIndexRoute: typeof LayoutPlanningScenariosCreateIndexRoute
  LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyRoute: typeof LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyRoute
  LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyRoute: typeof LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyRoute
  LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyRoute: typeof LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyRoute
  LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyRoute: typeof LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyRoute
  LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyRoute: typeof LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyRoute
  LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexRoute: typeof LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexRoute
  LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdDetailsIndexRoute: typeof LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdDetailsIndexRoute
}

const LayoutLazyRouteChildren: LayoutLazyRouteChildren = {
  LayoutWorkAreaRoute: LayoutWorkAreaRouteWithChildren,
  LayoutAdministrationAccessControlLazyRoute:
    LayoutAdministrationAccessControlLazyRouteWithChildren,
  LayoutAdministrationConfigurationLazyRoute:
    LayoutAdministrationConfigurationLazyRouteWithChildren,
  LayoutAdministrationIndexRoute: LayoutAdministrationIndexRoute,
  LayoutPlanningIndexRoute: LayoutPlanningIndexRoute,
  LayoutReportsAndAnalyticsIndexRoute: LayoutReportsAndAnalyticsIndexRoute,
  LayoutDashboardIndexLazyRoute: LayoutDashboardIndexLazyRoute,
  LayoutPlanningDemandAndPromotionsIndexRoute:
    LayoutPlanningDemandAndPromotionsIndexRoute,
  LayoutPlanningPromotionalCampaignsIndexRoute:
    LayoutPlanningPromotionalCampaignsIndexRoute,
  LayoutPlanningScenariosIndexRoute: LayoutPlanningScenariosIndexRoute,
  LayoutPlanningEventsCalendarIndexLazyRoute:
    LayoutPlanningEventsCalendarIndexLazyRoute,
  LayoutPlanningInvitedSuppliersIndexLazyRoute:
    LayoutPlanningInvitedSuppliersIndexLazyRoute,
  LayoutPlanningProcurementCalendarIndexLazyRoute:
    LayoutPlanningProcurementCalendarIndexLazyRoute,
  LayoutPlanningPromotionalCalendarIndexLazyRoute:
    LayoutPlanningPromotionalCalendarIndexLazyRoute,
  LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyRoute:
    LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyRoute,
  LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyRoute:
    LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyRoute,
  LayoutReportsAndAnalyticsForecastPerformanceIndexLazyRoute:
    LayoutReportsAndAnalyticsForecastPerformanceIndexLazyRoute,
  LayoutReportsAndAnalyticsOverallPerformanceIndexLazyRoute:
    LayoutReportsAndAnalyticsOverallPerformanceIndexLazyRoute,
  LayoutReportsAndAnalyticsStockPerformanceIndexLazyRoute:
    LayoutReportsAndAnalyticsStockPerformanceIndexLazyRoute,
  LayoutPlanningDemandAndPromotionsDetailsIndexRoute:
    LayoutPlanningDemandAndPromotionsDetailsIndexRoute,
  LayoutPlanningScenariosScenarioIdIndexRoute:
    LayoutPlanningScenariosScenarioIdIndexRoute,
  LayoutPlanningScenariosCreateIndexRoute:
    LayoutPlanningScenariosCreateIndexRoute,
  LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyRoute:
    LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyRoute,
  LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyRoute:
    LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyRoute,
  LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyRoute:
    LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyRoute,
  LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyRoute:
    LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyRoute,
  LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyRoute:
    LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyRoute,
  LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexRoute:
    LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexRoute,
  LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdDetailsIndexRoute:
    LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdDetailsIndexRoute,
}

const LayoutLazyRouteWithChildren = LayoutLazyRoute._addFileChildren(
  LayoutLazyRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/login': typeof LoginRoute
  '': typeof LayoutLazyRouteWithChildren
  '/logout': typeof LogoutLazyRoute
  '/work-area': typeof LayoutWorkAreaRouteWithChildren
  '/administration/access-control': typeof LayoutAdministrationAccessControlLazyRouteWithChildren
  '/administration/configuration': typeof LayoutAdministrationConfigurationLazyRouteWithChildren
  '/administration': typeof LayoutAdministrationIndexRoute
  '/planning': typeof LayoutPlanningIndexRoute
  '/reports-and-analytics': typeof LayoutReportsAndAnalyticsIndexRoute
  '/dashboard': typeof LayoutDashboardIndexLazyRoute
  '/administration/access-control/': typeof LayoutAdministrationAccessControlIndexRoute
  '/administration/configuration/': typeof LayoutAdministrationConfigurationIndexRoute
  '/planning/demand-and-promotions': typeof LayoutPlanningDemandAndPromotionsIndexRoute
  '/planning/promotional-campaigns': typeof LayoutPlanningPromotionalCampaignsIndexRoute
  '/planning/scenarios': typeof LayoutPlanningScenariosIndexRoute
  '/work-area/inventory-balancing': typeof LayoutWorkAreaInventoryBalancingIndexRoute
  '/work-area/procurement': typeof LayoutWorkAreaProcurementIndexRoute
  '/work-area/replenishment': typeof LayoutWorkAreaReplenishmentIndexRoute
  '/planning/events-calendar': typeof LayoutPlanningEventsCalendarIndexLazyRoute
  '/planning/invited-suppliers': typeof LayoutPlanningInvitedSuppliersIndexLazyRoute
  '/planning/procurement-calendar': typeof LayoutPlanningProcurementCalendarIndexLazyRoute
  '/planning/promotional-calendar': typeof LayoutPlanningPromotionalCalendarIndexLazyRoute
  '/reports-and-analytics/adherence-performance': typeof LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyRoute
  '/reports-and-analytics/commercial-performance': typeof LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyRoute
  '/reports-and-analytics/forecast-performance': typeof LayoutReportsAndAnalyticsForecastPerformanceIndexLazyRoute
  '/reports-and-analytics/overall-performance': typeof LayoutReportsAndAnalyticsOverallPerformanceIndexLazyRoute
  '/reports-and-analytics/stock-performance': typeof LayoutReportsAndAnalyticsStockPerformanceIndexLazyRoute
  '/work-area/price-definition': typeof LayoutWorkAreaPriceDefinitionIndexLazyRoute
  '/work-area/surplurs-management': typeof LayoutWorkAreaSurplursManagementIndexLazyRoute
  '/administration/configuration/general-parameters': typeof LayoutAdministrationConfigurationGeneralParametersIndexRoute
  '/planning/demand-and-promotions/details': typeof LayoutPlanningDemandAndPromotionsDetailsIndexRoute
  '/planning/scenarios/$scenarioId': typeof LayoutPlanningScenariosScenarioIdIndexRoute
  '/planning/scenarios/create': typeof LayoutPlanningScenariosCreateIndexRoute
  '/work-area/procurement/calendar': typeof LayoutWorkAreaProcurementCalendarIndexRoute
  '/work-area/procurement/dashboard': typeof LayoutWorkAreaProcurementDashboardIndexRoute
  '/work-area/procurement/order': typeof LayoutWorkAreaProcurementOrderIndexRoute
  '/work-area/replenishment/order': typeof LayoutWorkAreaReplenishmentOrderIndexRoute
  '/work-area/replenishment/suggestions': typeof LayoutWorkAreaReplenishmentSuggestionsIndexRoute
  '/administration/access-control/data-permission': typeof LayoutAdministrationAccessControlDataPermissionIndexLazyRoute
  '/administration/access-control/roles-and-permissions': typeof LayoutAdministrationAccessControlRolesAndPermissionsIndexLazyRoute
  '/administration/access-control/users': typeof LayoutAdministrationAccessControlUsersIndexLazyRoute
  '/administration/configuration/app-filters': typeof LayoutAdministrationConfigurationAppFiltersIndexLazyRoute
  '/administration/configuration/column-groups': typeof LayoutAdministrationConfigurationColumnGroupsIndexLazyRoute
  '/administration/configuration/config-columns': typeof LayoutAdministrationConfigurationConfigColumnsIndexLazyRoute
  '/administration/configuration/data-allocation': typeof LayoutAdministrationConfigurationDataAllocationIndexLazyRoute
  '/administration/configuration/environment-variables': typeof LayoutAdministrationConfigurationEnvironmentVariablesIndexLazyRoute
  '/administration/configuration/other': typeof LayoutAdministrationConfigurationOtherIndexLazyRoute
  '/administration/configuration/query-builder': typeof LayoutAdministrationConfigurationQueryBuilderIndexLazyRoute
  '/administration/configuration/synthetic-products': typeof LayoutAdministrationConfigurationSyntheticProductsIndexLazyRoute
  '/planning/promotional-campaigns/$campaignId': typeof LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyRoute
  '/reports-and-analytics/stock-performance/lost-sale': typeof LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyRoute
  '/reports-and-analytics/stock-performance/metrics': typeof LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyRoute
  '/reports-and-analytics/stock-performance/overstock': typeof LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyRoute
  '/reports-and-analytics/stock-performance/procurement': typeof LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyRoute
  '/work-area/procurement/calendar/order': typeof LayoutWorkAreaProcurementCalendarOrderIndexRoute
  '/administration/access-control/users/$userId': typeof LayoutAdministrationAccessControlUsersUserIdIndexLazyRoute
  '/administration/configuration/column-groups/$columnGroupId': typeof LayoutAdministrationConfigurationColumnGroupsColumnGroupIdIndexLazyRoute
  '/administration/configuration/query-builder/$id': typeof LayoutAdministrationConfigurationQueryBuilderIdIndexLazyRoute
  '/administration/configuration/synthetic-products/$id': typeof LayoutAdministrationConfigurationSyntheticProductsIdIndexLazyRoute
  '/planning/promotional-campaigns/$campaignId/promotion/$promotionId': typeof LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexRoute
  '/planning/promotional-campaigns/$campaignId/promotion/$promotionId/details': typeof LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdDetailsIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/login': typeof LoginRoute
  '': typeof LayoutLazyRouteWithChildren
  '/logout': typeof LogoutLazyRoute
  '/work-area': typeof LayoutWorkAreaRouteWithChildren
  '/administration': typeof LayoutAdministrationIndexRoute
  '/planning': typeof LayoutPlanningIndexRoute
  '/reports-and-analytics': typeof LayoutReportsAndAnalyticsIndexRoute
  '/dashboard': typeof LayoutDashboardIndexLazyRoute
  '/administration/access-control': typeof LayoutAdministrationAccessControlIndexRoute
  '/administration/configuration': typeof LayoutAdministrationConfigurationIndexRoute
  '/planning/demand-and-promotions': typeof LayoutPlanningDemandAndPromotionsIndexRoute
  '/planning/promotional-campaigns': typeof LayoutPlanningPromotionalCampaignsIndexRoute
  '/planning/scenarios': typeof LayoutPlanningScenariosIndexRoute
  '/work-area/inventory-balancing': typeof LayoutWorkAreaInventoryBalancingIndexRoute
  '/work-area/procurement': typeof LayoutWorkAreaProcurementIndexRoute
  '/work-area/replenishment': typeof LayoutWorkAreaReplenishmentIndexRoute
  '/planning/events-calendar': typeof LayoutPlanningEventsCalendarIndexLazyRoute
  '/planning/invited-suppliers': typeof LayoutPlanningInvitedSuppliersIndexLazyRoute
  '/planning/procurement-calendar': typeof LayoutPlanningProcurementCalendarIndexLazyRoute
  '/planning/promotional-calendar': typeof LayoutPlanningPromotionalCalendarIndexLazyRoute
  '/reports-and-analytics/adherence-performance': typeof LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyRoute
  '/reports-and-analytics/commercial-performance': typeof LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyRoute
  '/reports-and-analytics/forecast-performance': typeof LayoutReportsAndAnalyticsForecastPerformanceIndexLazyRoute
  '/reports-and-analytics/overall-performance': typeof LayoutReportsAndAnalyticsOverallPerformanceIndexLazyRoute
  '/reports-and-analytics/stock-performance': typeof LayoutReportsAndAnalyticsStockPerformanceIndexLazyRoute
  '/work-area/price-definition': typeof LayoutWorkAreaPriceDefinitionIndexLazyRoute
  '/work-area/surplurs-management': typeof LayoutWorkAreaSurplursManagementIndexLazyRoute
  '/administration/configuration/general-parameters': typeof LayoutAdministrationConfigurationGeneralParametersIndexRoute
  '/planning/demand-and-promotions/details': typeof LayoutPlanningDemandAndPromotionsDetailsIndexRoute
  '/planning/scenarios/$scenarioId': typeof LayoutPlanningScenariosScenarioIdIndexRoute
  '/planning/scenarios/create': typeof LayoutPlanningScenariosCreateIndexRoute
  '/work-area/procurement/calendar': typeof LayoutWorkAreaProcurementCalendarIndexRoute
  '/work-area/procurement/dashboard': typeof LayoutWorkAreaProcurementDashboardIndexRoute
  '/work-area/procurement/order': typeof LayoutWorkAreaProcurementOrderIndexRoute
  '/work-area/replenishment/order': typeof LayoutWorkAreaReplenishmentOrderIndexRoute
  '/work-area/replenishment/suggestions': typeof LayoutWorkAreaReplenishmentSuggestionsIndexRoute
  '/administration/access-control/data-permission': typeof LayoutAdministrationAccessControlDataPermissionIndexLazyRoute
  '/administration/access-control/roles-and-permissions': typeof LayoutAdministrationAccessControlRolesAndPermissionsIndexLazyRoute
  '/administration/access-control/users': typeof LayoutAdministrationAccessControlUsersIndexLazyRoute
  '/administration/configuration/app-filters': typeof LayoutAdministrationConfigurationAppFiltersIndexLazyRoute
  '/administration/configuration/column-groups': typeof LayoutAdministrationConfigurationColumnGroupsIndexLazyRoute
  '/administration/configuration/config-columns': typeof LayoutAdministrationConfigurationConfigColumnsIndexLazyRoute
  '/administration/configuration/data-allocation': typeof LayoutAdministrationConfigurationDataAllocationIndexLazyRoute
  '/administration/configuration/environment-variables': typeof LayoutAdministrationConfigurationEnvironmentVariablesIndexLazyRoute
  '/administration/configuration/other': typeof LayoutAdministrationConfigurationOtherIndexLazyRoute
  '/administration/configuration/query-builder': typeof LayoutAdministrationConfigurationQueryBuilderIndexLazyRoute
  '/administration/configuration/synthetic-products': typeof LayoutAdministrationConfigurationSyntheticProductsIndexLazyRoute
  '/planning/promotional-campaigns/$campaignId': typeof LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyRoute
  '/reports-and-analytics/stock-performance/lost-sale': typeof LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyRoute
  '/reports-and-analytics/stock-performance/metrics': typeof LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyRoute
  '/reports-and-analytics/stock-performance/overstock': typeof LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyRoute
  '/reports-and-analytics/stock-performance/procurement': typeof LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyRoute
  '/work-area/procurement/calendar/order': typeof LayoutWorkAreaProcurementCalendarOrderIndexRoute
  '/administration/access-control/users/$userId': typeof LayoutAdministrationAccessControlUsersUserIdIndexLazyRoute
  '/administration/configuration/column-groups/$columnGroupId': typeof LayoutAdministrationConfigurationColumnGroupsColumnGroupIdIndexLazyRoute
  '/administration/configuration/query-builder/$id': typeof LayoutAdministrationConfigurationQueryBuilderIdIndexLazyRoute
  '/administration/configuration/synthetic-products/$id': typeof LayoutAdministrationConfigurationSyntheticProductsIdIndexLazyRoute
  '/planning/promotional-campaigns/$campaignId/promotion/$promotionId': typeof LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexRoute
  '/planning/promotional-campaigns/$campaignId/promotion/$promotionId/details': typeof LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdDetailsIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/login': typeof LoginRoute
  '/_layout': typeof LayoutLazyRouteWithChildren
  '/logout': typeof LogoutLazyRoute
  '/_layout/work-area': typeof LayoutWorkAreaRouteWithChildren
  '/_layout/administration/access-control': typeof LayoutAdministrationAccessControlLazyRouteWithChildren
  '/_layout/administration/configuration': typeof LayoutAdministrationConfigurationLazyRouteWithChildren
  '/_layout/administration/': typeof LayoutAdministrationIndexRoute
  '/_layout/planning/': typeof LayoutPlanningIndexRoute
  '/_layout/reports-and-analytics/': typeof LayoutReportsAndAnalyticsIndexRoute
  '/_layout/dashboard/': typeof LayoutDashboardIndexLazyRoute
  '/_layout/administration/access-control/': typeof LayoutAdministrationAccessControlIndexRoute
  '/_layout/administration/configuration/': typeof LayoutAdministrationConfigurationIndexRoute
  '/_layout/planning/demand-and-promotions/': typeof LayoutPlanningDemandAndPromotionsIndexRoute
  '/_layout/planning/promotional-campaigns/': typeof LayoutPlanningPromotionalCampaignsIndexRoute
  '/_layout/planning/scenarios/': typeof LayoutPlanningScenariosIndexRoute
  '/_layout/work-area/inventory-balancing/': typeof LayoutWorkAreaInventoryBalancingIndexRoute
  '/_layout/work-area/procurement/': typeof LayoutWorkAreaProcurementIndexRoute
  '/_layout/work-area/replenishment/': typeof LayoutWorkAreaReplenishmentIndexRoute
  '/_layout/planning/events-calendar/': typeof LayoutPlanningEventsCalendarIndexLazyRoute
  '/_layout/planning/invited-suppliers/': typeof LayoutPlanningInvitedSuppliersIndexLazyRoute
  '/_layout/planning/procurement-calendar/': typeof LayoutPlanningProcurementCalendarIndexLazyRoute
  '/_layout/planning/promotional-calendar/': typeof LayoutPlanningPromotionalCalendarIndexLazyRoute
  '/_layout/reports-and-analytics/adherence-performance/': typeof LayoutReportsAndAnalyticsAdherencePerformanceIndexLazyRoute
  '/_layout/reports-and-analytics/commercial-performance/': typeof LayoutReportsAndAnalyticsCommercialPerformanceIndexLazyRoute
  '/_layout/reports-and-analytics/forecast-performance/': typeof LayoutReportsAndAnalyticsForecastPerformanceIndexLazyRoute
  '/_layout/reports-and-analytics/overall-performance/': typeof LayoutReportsAndAnalyticsOverallPerformanceIndexLazyRoute
  '/_layout/reports-and-analytics/stock-performance/': typeof LayoutReportsAndAnalyticsStockPerformanceIndexLazyRoute
  '/_layout/work-area/price-definition/': typeof LayoutWorkAreaPriceDefinitionIndexLazyRoute
  '/_layout/work-area/surplurs-management/': typeof LayoutWorkAreaSurplursManagementIndexLazyRoute
  '/_layout/administration/configuration/general-parameters/': typeof LayoutAdministrationConfigurationGeneralParametersIndexRoute
  '/_layout/planning/demand-and-promotions/details/': typeof LayoutPlanningDemandAndPromotionsDetailsIndexRoute
  '/_layout/planning/scenarios/$scenarioId/': typeof LayoutPlanningScenariosScenarioIdIndexRoute
  '/_layout/planning/scenarios/create/': typeof LayoutPlanningScenariosCreateIndexRoute
  '/_layout/work-area/procurement/calendar/': typeof LayoutWorkAreaProcurementCalendarIndexRoute
  '/_layout/work-area/procurement/dashboard/': typeof LayoutWorkAreaProcurementDashboardIndexRoute
  '/_layout/work-area/procurement/order/': typeof LayoutWorkAreaProcurementOrderIndexRoute
  '/_layout/work-area/replenishment/order/': typeof LayoutWorkAreaReplenishmentOrderIndexRoute
  '/_layout/work-area/replenishment/suggestions/': typeof LayoutWorkAreaReplenishmentSuggestionsIndexRoute
  '/_layout/administration/access-control/data-permission/': typeof LayoutAdministrationAccessControlDataPermissionIndexLazyRoute
  '/_layout/administration/access-control/roles-and-permissions/': typeof LayoutAdministrationAccessControlRolesAndPermissionsIndexLazyRoute
  '/_layout/administration/access-control/users/': typeof LayoutAdministrationAccessControlUsersIndexLazyRoute
  '/_layout/administration/configuration/app-filters/': typeof LayoutAdministrationConfigurationAppFiltersIndexLazyRoute
  '/_layout/administration/configuration/column-groups/': typeof LayoutAdministrationConfigurationColumnGroupsIndexLazyRoute
  '/_layout/administration/configuration/config-columns/': typeof LayoutAdministrationConfigurationConfigColumnsIndexLazyRoute
  '/_layout/administration/configuration/data-allocation/': typeof LayoutAdministrationConfigurationDataAllocationIndexLazyRoute
  '/_layout/administration/configuration/environment-variables/': typeof LayoutAdministrationConfigurationEnvironmentVariablesIndexLazyRoute
  '/_layout/administration/configuration/other/': typeof LayoutAdministrationConfigurationOtherIndexLazyRoute
  '/_layout/administration/configuration/query-builder/': typeof LayoutAdministrationConfigurationQueryBuilderIndexLazyRoute
  '/_layout/administration/configuration/synthetic-products/': typeof LayoutAdministrationConfigurationSyntheticProductsIndexLazyRoute
  '/_layout/planning/promotional-campaigns/$campaignId/': typeof LayoutPlanningPromotionalCampaignsCampaignIdIndexLazyRoute
  '/_layout/reports-and-analytics/stock-performance/lost-sale/': typeof LayoutReportsAndAnalyticsStockPerformanceLostSaleIndexLazyRoute
  '/_layout/reports-and-analytics/stock-performance/metrics/': typeof LayoutReportsAndAnalyticsStockPerformanceMetricsIndexLazyRoute
  '/_layout/reports-and-analytics/stock-performance/overstock/': typeof LayoutReportsAndAnalyticsStockPerformanceOverstockIndexLazyRoute
  '/_layout/reports-and-analytics/stock-performance/procurement/': typeof LayoutReportsAndAnalyticsStockPerformanceProcurementIndexLazyRoute
  '/_layout/work-area/procurement/calendar/order/': typeof LayoutWorkAreaProcurementCalendarOrderIndexRoute
  '/_layout/administration/access-control/users/$userId/': typeof LayoutAdministrationAccessControlUsersUserIdIndexLazyRoute
  '/_layout/administration/configuration/column-groups/$columnGroupId/': typeof LayoutAdministrationConfigurationColumnGroupsColumnGroupIdIndexLazyRoute
  '/_layout/administration/configuration/query-builder/$id/': typeof LayoutAdministrationConfigurationQueryBuilderIdIndexLazyRoute
  '/_layout/administration/configuration/synthetic-products/$id/': typeof LayoutAdministrationConfigurationSyntheticProductsIdIndexLazyRoute
  '/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/': typeof LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdIndexRoute
  '/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/details/': typeof LayoutPlanningPromotionalCampaignsCampaignIdPromotionPromotionIdDetailsIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/login'
    | ''
    | '/logout'
    | '/work-area'
    | '/administration/access-control'
    | '/administration/configuration'
    | '/administration'
    | '/planning'
    | '/reports-and-analytics'
    | '/dashboard'
    | '/administration/access-control/'
    | '/administration/configuration/'
    | '/planning/demand-and-promotions'
    | '/planning/promotional-campaigns'
    | '/planning/scenarios'
    | '/work-area/inventory-balancing'
    | '/work-area/procurement'
    | '/work-area/replenishment'
    | '/planning/events-calendar'
    | '/planning/invited-suppliers'
    | '/planning/procurement-calendar'
    | '/planning/promotional-calendar'
    | '/reports-and-analytics/adherence-performance'
    | '/reports-and-analytics/commercial-performance'
    | '/reports-and-analytics/forecast-performance'
    | '/reports-and-analytics/overall-performance'
    | '/reports-and-analytics/stock-performance'
    | '/work-area/price-definition'
    | '/work-area/surplurs-management'
    | '/administration/configuration/general-parameters'
    | '/planning/demand-and-promotions/details'
    | '/planning/scenarios/$scenarioId'
    | '/planning/scenarios/create'
    | '/work-area/procurement/calendar'
    | '/work-area/procurement/dashboard'
    | '/work-area/procurement/order'
    | '/work-area/replenishment/order'
    | '/work-area/replenishment/suggestions'
    | '/administration/access-control/data-permission'
    | '/administration/access-control/roles-and-permissions'
    | '/administration/access-control/users'
    | '/administration/configuration/app-filters'
    | '/administration/configuration/column-groups'
    | '/administration/configuration/config-columns'
    | '/administration/configuration/data-allocation'
    | '/administration/configuration/environment-variables'
    | '/administration/configuration/other'
    | '/administration/configuration/query-builder'
    | '/administration/configuration/synthetic-products'
    | '/planning/promotional-campaigns/$campaignId'
    | '/reports-and-analytics/stock-performance/lost-sale'
    | '/reports-and-analytics/stock-performance/metrics'
    | '/reports-and-analytics/stock-performance/overstock'
    | '/reports-and-analytics/stock-performance/procurement'
    | '/work-area/procurement/calendar/order'
    | '/administration/access-control/users/$userId'
    | '/administration/configuration/column-groups/$columnGroupId'
    | '/administration/configuration/query-builder/$id'
    | '/administration/configuration/synthetic-products/$id'
    | '/planning/promotional-campaigns/$campaignId/promotion/$promotionId'
    | '/planning/promotional-campaigns/$campaignId/promotion/$promotionId/details'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/login'
    | ''
    | '/logout'
    | '/work-area'
    | '/administration'
    | '/planning'
    | '/reports-and-analytics'
    | '/dashboard'
    | '/administration/access-control'
    | '/administration/configuration'
    | '/planning/demand-and-promotions'
    | '/planning/promotional-campaigns'
    | '/planning/scenarios'
    | '/work-area/inventory-balancing'
    | '/work-area/procurement'
    | '/work-area/replenishment'
    | '/planning/events-calendar'
    | '/planning/invited-suppliers'
    | '/planning/procurement-calendar'
    | '/planning/promotional-calendar'
    | '/reports-and-analytics/adherence-performance'
    | '/reports-and-analytics/commercial-performance'
    | '/reports-and-analytics/forecast-performance'
    | '/reports-and-analytics/overall-performance'
    | '/reports-and-analytics/stock-performance'
    | '/work-area/price-definition'
    | '/work-area/surplurs-management'
    | '/administration/configuration/general-parameters'
    | '/planning/demand-and-promotions/details'
    | '/planning/scenarios/$scenarioId'
    | '/planning/scenarios/create'
    | '/work-area/procurement/calendar'
    | '/work-area/procurement/dashboard'
    | '/work-area/procurement/order'
    | '/work-area/replenishment/order'
    | '/work-area/replenishment/suggestions'
    | '/administration/access-control/data-permission'
    | '/administration/access-control/roles-and-permissions'
    | '/administration/access-control/users'
    | '/administration/configuration/app-filters'
    | '/administration/configuration/column-groups'
    | '/administration/configuration/config-columns'
    | '/administration/configuration/data-allocation'
    | '/administration/configuration/environment-variables'
    | '/administration/configuration/other'
    | '/administration/configuration/query-builder'
    | '/administration/configuration/synthetic-products'
    | '/planning/promotional-campaigns/$campaignId'
    | '/reports-and-analytics/stock-performance/lost-sale'
    | '/reports-and-analytics/stock-performance/metrics'
    | '/reports-and-analytics/stock-performance/overstock'
    | '/reports-and-analytics/stock-performance/procurement'
    | '/work-area/procurement/calendar/order'
    | '/administration/access-control/users/$userId'
    | '/administration/configuration/column-groups/$columnGroupId'
    | '/administration/configuration/query-builder/$id'
    | '/administration/configuration/synthetic-products/$id'
    | '/planning/promotional-campaigns/$campaignId/promotion/$promotionId'
    | '/planning/promotional-campaigns/$campaignId/promotion/$promotionId/details'
  id:
    | '__root__'
    | '/'
    | '/login'
    | '/_layout'
    | '/logout'
    | '/_layout/work-area'
    | '/_layout/administration/access-control'
    | '/_layout/administration/configuration'
    | '/_layout/administration/'
    | '/_layout/planning/'
    | '/_layout/reports-and-analytics/'
    | '/_layout/dashboard/'
    | '/_layout/administration/access-control/'
    | '/_layout/administration/configuration/'
    | '/_layout/planning/demand-and-promotions/'
    | '/_layout/planning/promotional-campaigns/'
    | '/_layout/planning/scenarios/'
    | '/_layout/work-area/inventory-balancing/'
    | '/_layout/work-area/procurement/'
    | '/_layout/work-area/replenishment/'
    | '/_layout/planning/events-calendar/'
    | '/_layout/planning/invited-suppliers/'
    | '/_layout/planning/procurement-calendar/'
    | '/_layout/planning/promotional-calendar/'
    | '/_layout/reports-and-analytics/adherence-performance/'
    | '/_layout/reports-and-analytics/commercial-performance/'
    | '/_layout/reports-and-analytics/forecast-performance/'
    | '/_layout/reports-and-analytics/overall-performance/'
    | '/_layout/reports-and-analytics/stock-performance/'
    | '/_layout/work-area/price-definition/'
    | '/_layout/work-area/surplurs-management/'
    | '/_layout/administration/configuration/general-parameters/'
    | '/_layout/planning/demand-and-promotions/details/'
    | '/_layout/planning/scenarios/$scenarioId/'
    | '/_layout/planning/scenarios/create/'
    | '/_layout/work-area/procurement/calendar/'
    | '/_layout/work-area/procurement/dashboard/'
    | '/_layout/work-area/procurement/order/'
    | '/_layout/work-area/replenishment/order/'
    | '/_layout/work-area/replenishment/suggestions/'
    | '/_layout/administration/access-control/data-permission/'
    | '/_layout/administration/access-control/roles-and-permissions/'
    | '/_layout/administration/access-control/users/'
    | '/_layout/administration/configuration/app-filters/'
    | '/_layout/administration/configuration/column-groups/'
    | '/_layout/administration/configuration/config-columns/'
    | '/_layout/administration/configuration/data-allocation/'
    | '/_layout/administration/configuration/environment-variables/'
    | '/_layout/administration/configuration/other/'
    | '/_layout/administration/configuration/query-builder/'
    | '/_layout/administration/configuration/synthetic-products/'
    | '/_layout/planning/promotional-campaigns/$campaignId/'
    | '/_layout/reports-and-analytics/stock-performance/lost-sale/'
    | '/_layout/reports-and-analytics/stock-performance/metrics/'
    | '/_layout/reports-and-analytics/stock-performance/overstock/'
    | '/_layout/reports-and-analytics/stock-performance/procurement/'
    | '/_layout/work-area/procurement/calendar/order/'
    | '/_layout/administration/access-control/users/$userId/'
    | '/_layout/administration/configuration/column-groups/$columnGroupId/'
    | '/_layout/administration/configuration/query-builder/$id/'
    | '/_layout/administration/configuration/synthetic-products/$id/'
    | '/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/'
    | '/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/details/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  LoginRoute: typeof LoginRoute
  LayoutLazyRoute: typeof LayoutLazyRouteWithChildren
  LogoutLazyRoute: typeof LogoutLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  LoginRoute: LoginRoute,
  LayoutLazyRoute: LayoutLazyRouteWithChildren,
  LogoutLazyRoute: LogoutLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/login",
        "/_layout",
        "/logout"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/_layout": {
      "filePath": "_layout.lazy.tsx",
      "children": [
        "/_layout/work-area",
        "/_layout/administration/access-control",
        "/_layout/administration/configuration",
        "/_layout/administration/",
        "/_layout/planning/",
        "/_layout/reports-and-analytics/",
        "/_layout/dashboard/",
        "/_layout/planning/demand-and-promotions/",
        "/_layout/planning/promotional-campaigns/",
        "/_layout/planning/scenarios/",
        "/_layout/planning/events-calendar/",
        "/_layout/planning/invited-suppliers/",
        "/_layout/planning/procurement-calendar/",
        "/_layout/planning/promotional-calendar/",
        "/_layout/reports-and-analytics/adherence-performance/",
        "/_layout/reports-and-analytics/commercial-performance/",
        "/_layout/reports-and-analytics/forecast-performance/",
        "/_layout/reports-and-analytics/overall-performance/",
        "/_layout/reports-and-analytics/stock-performance/",
        "/_layout/planning/demand-and-promotions/details/",
        "/_layout/planning/scenarios/$scenarioId/",
        "/_layout/planning/scenarios/create/",
        "/_layout/planning/promotional-campaigns/$campaignId/",
        "/_layout/reports-and-analytics/stock-performance/lost-sale/",
        "/_layout/reports-and-analytics/stock-performance/metrics/",
        "/_layout/reports-and-analytics/stock-performance/overstock/",
        "/_layout/reports-and-analytics/stock-performance/procurement/",
        "/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/",
        "/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/details/"
      ]
    },
    "/logout": {
      "filePath": "logout.lazy.tsx"
    },
    "/_layout/work-area": {
      "filePath": "_layout.work-area.tsx",
      "parent": "/_layout",
      "children": [
        "/_layout/work-area/inventory-balancing/",
        "/_layout/work-area/procurement/",
        "/_layout/work-area/replenishment/",
        "/_layout/work-area/price-definition/",
        "/_layout/work-area/surplurs-management/",
        "/_layout/work-area/procurement/calendar/",
        "/_layout/work-area/procurement/dashboard/",
        "/_layout/work-area/procurement/order/",
        "/_layout/work-area/replenishment/order/",
        "/_layout/work-area/replenishment/suggestions/",
        "/_layout/work-area/procurement/calendar/order/"
      ]
    },
    "/_layout/administration/access-control": {
      "filePath": "_layout.administration.access-control.lazy.tsx",
      "parent": "/_layout",
      "children": [
        "/_layout/administration/access-control/",
        "/_layout/administration/access-control/data-permission/",
        "/_layout/administration/access-control/roles-and-permissions/",
        "/_layout/administration/access-control/users/",
        "/_layout/administration/access-control/users/$userId/"
      ]
    },
    "/_layout/administration/configuration": {
      "filePath": "_layout.administration.configuration.lazy.tsx",
      "parent": "/_layout",
      "children": [
        "/_layout/administration/configuration/",
        "/_layout/administration/configuration/general-parameters/",
        "/_layout/administration/configuration/app-filters/",
        "/_layout/administration/configuration/column-groups/",
        "/_layout/administration/configuration/config-columns/",
        "/_layout/administration/configuration/data-allocation/",
        "/_layout/administration/configuration/environment-variables/",
        "/_layout/administration/configuration/other/",
        "/_layout/administration/configuration/query-builder/",
        "/_layout/administration/configuration/synthetic-products/",
        "/_layout/administration/configuration/column-groups/$columnGroupId/",
        "/_layout/administration/configuration/query-builder/$id/",
        "/_layout/administration/configuration/synthetic-products/$id/"
      ]
    },
    "/_layout/administration/": {
      "filePath": "_layout.administration.index.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/": {
      "filePath": "_layout.planning.index.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/": {
      "filePath": "_layout.reports-and-analytics.index.tsx",
      "parent": "/_layout"
    },
    "/_layout/dashboard/": {
      "filePath": "_layout.dashboard.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/administration/access-control/": {
      "filePath": "_layout.administration.access-control.index.tsx",
      "parent": "/_layout/administration/access-control"
    },
    "/_layout/administration/configuration/": {
      "filePath": "_layout.administration.configuration.index.tsx",
      "parent": "/_layout/administration/configuration"
    },
    "/_layout/planning/demand-and-promotions/": {
      "filePath": "_layout.planning.demand-and-promotions.index.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/promotional-campaigns/": {
      "filePath": "_layout.planning.promotional-campaigns.index.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/scenarios/": {
      "filePath": "_layout.planning.scenarios.index.tsx",
      "parent": "/_layout"
    },
    "/_layout/work-area/inventory-balancing/": {
      "filePath": "_layout.work-area.inventory-balancing.index.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/work-area/procurement/": {
      "filePath": "_layout.work-area.procurement.index.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/work-area/replenishment/": {
      "filePath": "_layout.work-area.replenishment.index.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/planning/events-calendar/": {
      "filePath": "_layout.planning.events-calendar.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/invited-suppliers/": {
      "filePath": "_layout.planning.invited-suppliers.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/procurement-calendar/": {
      "filePath": "_layout.planning.procurement-calendar.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/promotional-calendar/": {
      "filePath": "_layout.planning.promotional-calendar.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/adherence-performance/": {
      "filePath": "_layout.reports-and-analytics.adherence-performance.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/commercial-performance/": {
      "filePath": "_layout.reports-and-analytics.commercial-performance.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/forecast-performance/": {
      "filePath": "_layout.reports-and-analytics.forecast-performance.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/overall-performance/": {
      "filePath": "_layout.reports-and-analytics.overall-performance.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/stock-performance/": {
      "filePath": "_layout.reports-and-analytics.stock-performance.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/work-area/price-definition/": {
      "filePath": "_layout.work-area.price-definition.index.lazy.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/work-area/surplurs-management/": {
      "filePath": "_layout.work-area.surplurs-management.index.lazy.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/administration/configuration/general-parameters/": {
      "filePath": "_layout.administration.configuration.general-parameters.index.tsx",
      "parent": "/_layout/administration/configuration"
    },
    "/_layout/planning/demand-and-promotions/details/": {
      "filePath": "_layout.planning.demand-and-promotions.details.index.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/scenarios/$scenarioId/": {
      "filePath": "_layout.planning.scenarios.$scenarioId.index.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/scenarios/create/": {
      "filePath": "_layout.planning.scenarios.create.index.tsx",
      "parent": "/_layout"
    },
    "/_layout/work-area/procurement/calendar/": {
      "filePath": "_layout.work-area.procurement.calendar.index.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/work-area/procurement/dashboard/": {
      "filePath": "_layout.work-area.procurement.dashboard.index.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/work-area/procurement/order/": {
      "filePath": "_layout.work-area.procurement.order.index.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/work-area/replenishment/order/": {
      "filePath": "_layout.work-area.replenishment.order.index.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/work-area/replenishment/suggestions/": {
      "filePath": "_layout.work-area.replenishment.suggestions.index.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/administration/access-control/data-permission/": {
      "filePath": "_layout.administration.access-control.data-permission.index.lazy.tsx",
      "parent": "/_layout/administration/access-control"
    },
    "/_layout/administration/access-control/roles-and-permissions/": {
      "filePath": "_layout.administration.access-control.roles-and-permissions.index.lazy.tsx",
      "parent": "/_layout/administration/access-control"
    },
    "/_layout/administration/access-control/users/": {
      "filePath": "_layout.administration.access-control.users.index.lazy.tsx",
      "parent": "/_layout/administration/access-control"
    },
    "/_layout/administration/configuration/app-filters/": {
      "filePath": "_layout.administration.configuration.app-filters.index.lazy.tsx",
      "parent": "/_layout/administration/configuration"
    },
    "/_layout/administration/configuration/column-groups/": {
      "filePath": "_layout.administration.configuration.column-groups.index.lazy.tsx",
      "parent": "/_layout/administration/configuration"
    },
    "/_layout/administration/configuration/config-columns/": {
      "filePath": "_layout.administration.configuration.config-columns.index.lazy.tsx",
      "parent": "/_layout/administration/configuration"
    },
    "/_layout/administration/configuration/data-allocation/": {
      "filePath": "_layout.administration.configuration.data-allocation.index.lazy.tsx",
      "parent": "/_layout/administration/configuration"
    },
    "/_layout/administration/configuration/environment-variables/": {
      "filePath": "_layout.administration.configuration.environment-variables.index.lazy.tsx",
      "parent": "/_layout/administration/configuration"
    },
    "/_layout/administration/configuration/other/": {
      "filePath": "_layout.administration.configuration.other.index.lazy.tsx",
      "parent": "/_layout/administration/configuration"
    },
    "/_layout/administration/configuration/query-builder/": {
      "filePath": "_layout.administration.configuration.query-builder.index.lazy.tsx",
      "parent": "/_layout/administration/configuration"
    },
    "/_layout/administration/configuration/synthetic-products/": {
      "filePath": "_layout.administration.configuration.synthetic-products.index.lazy.tsx",
      "parent": "/_layout/administration/configuration"
    },
    "/_layout/planning/promotional-campaigns/$campaignId/": {
      "filePath": "_layout.planning.promotional-campaigns.$campaignId.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/stock-performance/lost-sale/": {
      "filePath": "_layout.reports-and-analytics.stock-performance.lost-sale.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/stock-performance/metrics/": {
      "filePath": "_layout.reports-and-analytics.stock-performance.metrics.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/stock-performance/overstock/": {
      "filePath": "_layout.reports-and-analytics.stock-performance.overstock.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/reports-and-analytics/stock-performance/procurement/": {
      "filePath": "_layout.reports-and-analytics.stock-performance.procurement.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/work-area/procurement/calendar/order/": {
      "filePath": "_layout.work-area.procurement.calendar.order.index.tsx",
      "parent": "/_layout/work-area"
    },
    "/_layout/administration/access-control/users/$userId/": {
      "filePath": "_layout.administration.access-control.users.$userId.index.lazy.tsx",
      "parent": "/_layout/administration/access-control"
    },
    "/_layout/administration/configuration/column-groups/$columnGroupId/": {
      "filePath": "_layout.administration.configuration.column-groups.$columnGroupId.index.lazy.tsx",
      "parent": "/_layout/administration/configuration"
    },
    "/_layout/administration/configuration/query-builder/$id/": {
      "filePath": "_layout.administration.configuration.query-builder.$id.index.lazy.tsx",
      "parent": "/_layout/administration/configuration"
    },
    "/_layout/administration/configuration/synthetic-products/$id/": {
      "filePath": "_layout.administration.configuration.synthetic-products.$id.index.lazy.tsx",
      "parent": "/_layout/administration/configuration"
    },
    "/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/": {
      "filePath": "_layout.planning.promotional-campaigns.$campaignId.promotion.$promotionId.index.tsx",
      "parent": "/_layout"
    },
    "/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/details/": {
      "filePath": "_layout.planning.promotional-campaigns.$campaignId.promotion.$promotionId.details.index.tsx",
      "parent": "/_layout"
    }
  }
}
ROUTE_MANIFEST_END */
